import { css } from '@emotion/react'
import { forwardRef } from 'react'
import {
  InputElementProps,
  InputLeftElementProps,
  InputRightElementProps,
} from './types'
import classNames from 'classnames'

export const InputElement = forwardRef<HTMLDivElement, InputElementProps>(
  (props, ref) => {
    const { placement = 'left', ...rest } = props

    const attr = placement === 'left' ? 'left' : 'right'

    return (
      <div
        ref={ref}
        css={css({
          [attr]: '0',
          position: 'absolute',
          display: 'flex',
          top: '0px',
          zIndex: 2,
          justifyContent: 'center',
          alignItems: 'center',
        })}
        {...rest}
      />
    )
  },
)

InputElement.displayName = 'InputElement'

export const InputLeftElement = forwardRef<
  HTMLDivElement,
  InputLeftElementProps
>((props, ref) => {
  const { className, ...rest } = props

  const classes = classNames('pq-input__element-left', className)

  return (
    <InputElement placement="left" ref={ref} className={classes} {...rest} />
  )
})

InputLeftElement.displayName = 'InputLeftElement'

export const InputRightElement = forwardRef<
  HTMLDivElement,
  InputRightElementProps
>((props, ref) => {
  const { className, ...rest } = props

  const classes = classNames('pq-input__element-right', className)

  return (
    <InputElement placement="right" ref={ref} className={classes} {...rest} />
  )
})

InputRightElement.displayName = 'InputRightElement'
