import { useState } from 'react'
import { EditorToolbarProps } from '../types'
import { EditorToolbarAttachImage } from './attach-image'
import { flip, offset, shift } from '@floating-ui/react'
import classNames from 'classnames'
import { AtSignIcon, BaselineIcon, SmileIcon } from 'lucide-react'
import { Popover } from '/@popover'
import { Button } from '/@button'
import { vars } from '/@theme/theme.css.ts'
import { EditorToolbarTextFormatting } from './text-formatting.tsx'
import { editorToolbarContainer } from './toolbar.css.ts'

const EditorToolbar = (props: EditorToolbarProps) => {
  const {
    editor,
    enableAttachAction = false,
    enableMentionAction = false,
    enableEmojiAction = false,
    disabled: btnDisabled,
  } = props

  const classes = classNames('pq-editor__toolbar', editorToolbarContainer)
  const [showBubbleMenu, setShowBubbleMenu] = useState(false)

  return (
    <div className={classes}>
      <Popover
        placement="bottom-end"
        enableClick
        role="dialog"
        middleware={[
          offset({
            mainAxis: 10,
          }),
          flip(),
          shift(),
        ]}
      >
        <Popover.Trigger>
          <Button
            variant="transparent"
            size="sm"
            onClick={(e) => {
              e.preventDefault()
              setShowBubbleMenu(!showBubbleMenu)
            }}
            disabled={btnDisabled}
          >
            <BaselineIcon size={24} color={vars.color.gray['60']} />
          </Button>
        </Popover.Trigger>
        <Popover.Content useArrow>
          <EditorToolbarTextFormatting editor={editor} />
        </Popover.Content>
      </Popover>
      {enableAttachAction && <EditorToolbarAttachImage editor={editor} />}
      {enableMentionAction && (
        <Button variant="transparent">
          <AtSignIcon />
        </Button>
      )}
      {enableEmojiAction && (
        <Button variant="transparent">
          <SmileIcon />
        </Button>
      )}
    </div>
  )
}

export { EditorToolbar }
