import { forwardRef } from 'react'
import { InputGroupProps } from './types'
import { CSSObject, css } from '@emotion/react'
import { getValidChildren } from '/@lib'
import { InputGroupProvider } from './context'

const InputGroup = forwardRef<HTMLDivElement, InputGroupProps>((props, ref) => {
  const { children } = props

  const validChildren = getValidChildren(children)

  const cssStyles: CSSObject = {}
  const inputBoardCssStyles: CSSObject = {}

  validChildren.forEach((child: any) => {
    if (child.type.displayName === 'InputLeftElement') {
      cssStyles.paddingInlineStart = '2.5rem'
    } else if (child.type.displayName === 'InputRightElement') {
      inputBoardCssStyles.flex = 1
    }
  })

  return (
    <InputGroupProvider
      data={{
        css: css(cssStyles),
        inputBoardCss: css(inputBoardCssStyles),
      }}
    >
      <div
        ref={ref}
        css={css`
          display: flex;
          width: 100%;
          position: relative;
        `}
      >
        {validChildren}
      </div>
    </InputGroupProvider>
  )
})

InputGroup.displayName = 'InputGroup'

export { InputGroup }
