import { css } from '@emotion/react'

export const ErrorWrapperCss = css({
  width: '100%',
  fontSize: '0.75rem',
  fontWeight: '600',
  lineHeight: '1.5',
  color: '#FF696D',
  height: '20px',
  textAlign: 'right',
})
