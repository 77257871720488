import { Editor } from '@tiptap/react'
import { Button } from '/@button'
import { EditorToolbarBubbleMenu } from './bubble-menu'
import { setCommonIconProps, setIsActive } from './lib'
import {
  BoldIcon,
  ItalicIcon,
  ListIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from 'lucide-react'
import { EditorToolbarTextAlignment } from './text-alignment'
import { verticalLineStyle } from './toolbar.css.ts'

const EditorToolbarTextFormatting = (props: { editor: Editor }) => {
  const { editor } = props

  const iconProps = setCommonIconProps()

  return (
    <EditorToolbarBubbleMenu>
      <Button
        className={setIsActive(editor, 'bold')}
        variant="transparent"
        onClick={() => {
          editor.chain().focus().toggleBold().run()
        }}
      >
        <BoldIcon {...iconProps} />
      </Button>
      <Button
        variant="transparent"
        className={setIsActive(editor, 'italic')}
        onClick={() => {
          editor.chain().focus().toggleItalic().run()
        }}
      >
        <ItalicIcon {...iconProps} />
      </Button>
      <Button
        variant="transparent"
        className={setIsActive(editor, 'underline')}
        onClick={() => {
          editor.chain().focus().toggleUnderline().run()
        }}
      >
        <UnderlineIcon {...iconProps} />
      </Button>
      <Button
        variant="transparent"
        className={setIsActive(editor, 'strike')}
        onClick={() => {
          editor.chain().focus().toggleStrike().run()
        }}
      >
        <StrikethroughIcon {...iconProps} />
      </Button>
      <Button
        variant="transparent"
        className={setIsActive(editor, 'bulletList')}
        onClick={() => {
          editor.chain().focus().toggleBulletList().run()
        }}
      >
        <ListIcon {...iconProps} />
      </Button>
      <div className={verticalLineStyle} />
      <EditorToolbarTextAlignment editor={editor} iconProps={iconProps} />
    </EditorToolbarBubbleMenu>
  )
}

export { EditorToolbarTextFormatting }
