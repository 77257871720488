import { SerializedStyles, css } from '@emotion/react'

export const TextAreaOuterCss = (outerCss?: SerializedStyles) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  ${outerCss}
`

export const TextAreaCss = css`
  &.pq-form__textarea {
    width: 100%;
    font-family: Poppins;
    border-radius: 8px;
    border: solid 2px rgba(203, 208, 223, 0.3);

    &.-error {
      border-color: rgba(255, 105, 109, 0.5);
    }

    outline: 0;
    white-space: pre-line;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    padding: 0.625em 0.625em 0.625em 1.25em;
    font-size: 0.813rem;
    line-height: 1.54;
    letter-spacing: normal;
    color: #0d1331;
  }
`

export const TextAreaCaptionCss = css`
  &.pq-form__textarea-caption {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 1em;
    margin-top: -0.625em;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5;
    color: #8a91a5;
    background-color: white;
    white-space: nowrap;
    padding: 0 0.5em;
    opacity: 1;
  }
`
