import { Editor } from '@tiptap/react'
import { Button } from '/@button'
import { setIsActive } from './lib'
import {
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  LucideProps,
} from 'lucide-react'
import { EditorToolbarBubbleMenu } from './bubble-menu'

const EditorToolbarTextAlignment = (props: {
  editor: Editor
  iconProps: LucideProps
}) => {
  const { editor, iconProps } = props

  return (
    <EditorToolbarBubbleMenu>
      <Button
        variant="transparent"
        className={setIsActive(editor, { textAlign: 'left' })}
        onClick={() => {
          editor.chain().focus().setTextAlign('left').run()
        }}
      >
        <AlignLeft {...iconProps} />
      </Button>
      <Button
        variant="transparent"
        className={setIsActive(editor, { textAlign: 'center' })}
        onClick={() => {
          editor.chain().focus().setTextAlign('center').run()
        }}
      >
        <AlignCenter {...iconProps} />
      </Button>
      <Button
        variant="transparent"
        className={setIsActive(editor, { textAlign: 'right' })}
        onClick={() => {
          editor.chain().focus().setTextAlign('right').run()
        }}
      >
        <AlignRight {...iconProps} />
      </Button>
      <Button
        variant="transparent"
        className={setIsActive(editor, { textAlign: 'justify' })}
        onClick={() => {
          editor.chain().focus().setTextAlign('justify').run()
        }}
      >
        <AlignJustify {...iconProps} />
      </Button>
    </EditorToolbarBubbleMenu>
  )
}

export { EditorToolbarTextAlignment }
