import classNames from 'classnames'
import { PropsWithChildren, forwardRef } from 'react'
import { PopoverTriggerProps } from './types'
import { PopperTrigger } from '/@popper'
import { popoverTriggerCursor, popoverTriggerStyle } from './popover.css.ts'
import { useFloatingUiContext } from '@palqee/utils'
import { assignInlineVars } from '@vanilla-extract/dynamic'

const PopoverTrigger = forwardRef<
  Element,
  PropsWithChildren<PopoverTriggerProps>
>((props, propRef) => {
  const { children, className, style, ...restProps } = props

  const context = useFloatingUiContext()
  const classes = classNames(
    'pq-popover__trigger',
    className,
    popoverTriggerStyle,
  )

  return (
    <PopperTrigger
      className={classes}
      ref={propRef}
      style={{
        ...(style ?? {}),
        ...assignInlineVars({
          [popoverTriggerCursor]: context?.enabled ? 'pointer' : 'auto',
        }),
      }}
      {...restProps}
    >
      {children}
    </PopperTrigger>
  )
})

PopoverTrigger.displayName = 'PopoverTrigger'

export { PopoverTrigger }
