import { Editor } from '@tiptap/react'
import { editorToolbarBubbleMenuInner } from './toolbar.css.ts'
import classNames from 'classnames'
import { vars } from '/@theme/theme.css'

export const setIsActive = (
  editor: Editor,
  option: string | { textAlign: string },
) => {
  return classNames(editorToolbarBubbleMenuInner, {
    '--is-active': editor.isActive(option),
  })
}

export const setCommonIconProps = () => {
  return {
    size: 18,
    color: vars.color.gray['60'],
  }
}
