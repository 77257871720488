import classNames from 'classnames'
import { ErrorMessage } from '@hookform/error-message'
import * as react from 'react'
import { IElementErrorProps } from './types'
import { ErrorWrapperCss } from './styles'

const ElementError = (props: IElementErrorProps) => {
  const { errors, showErrorBoard, className, name, ...rest } = props

  const classes = classNames('pq-form__errors', className)

  const renderError = () => {
    const errorWrapper = (message: react.ReactNode) => {
      return (
        (showErrorBoard || !!errors) && (
          <div
            data-cy="form-input-error-message"
            className={classes}
            {...rest}
            css={ErrorWrapperCss}
          >
            {message}
          </div>
        )
      )
    }

    return errorWrapper(<ErrorMessage errors={errors} name={name} />)
  }

  return <>{renderError()}</>
}

export { ElementError }
