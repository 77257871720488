import { createContext, useContext } from 'react'

const InputGroupContext = createContext(null)

export const useInputGroupContext = () => {
  const context = useContext(InputGroupContext)
  return context
}

export const InputGroupProvider = (props) => {
  const { children, ...data } = props

  return (
    <InputGroupContext.Provider value={data}>
      {children}
    </InputGroupContext.Provider>
  )
}
