import { forwardRef } from 'react'
import { InputProps } from './types'
import classNames from 'classnames'
import {
  InputOuterWrapperCss,
  InputTitleCss,
  InputTitleWrapperCss,
  InputWrapper,
} from './styles'
import { useCombinedRefs } from '@palqee/utils'
import { ElementError } from '../error'
import { css } from '@emotion/react'
import isEmpty from 'lodash/isEmpty'
import { useInputGroupContext } from './context'
import { Popover } from '/@popover'
import { InfoIcon } from 'lucide-react'
import { vars } from '/@theme/theme.css'

const Input = forwardRef<HTMLInputElement, InputProps>(
  (props, forwardedRef) => {
    const {
      className,
      // not sure how I feel about overriding
      // other elements using props like this
      inputBoardCss: inputBoardCssProps,
      caption,
      disabled,
      errors,
      showErrorBoard,
      required,
      tooltip,
      ...rest
    } = props

    const classes = classNames('pq-form__input', className, {
      ['-error']: !isEmpty(errors) && !!errors[rest.name],
    })

    const inputGroupContext = useInputGroupContext() ?? {}
    const combinedRef = useCombinedRefs(forwardedRef)

    const inputBoardCss = {
      ...inputBoardCssProps,
      ...inputGroupContext?.data?.inputBoardCss,
    }

    const renderInput = () => {
      return (
        <InputWrapper
          data-cy="pq-form__input"
          className={classes}
          ref={combinedRef}
          disabled={disabled}
          autoComplete="off"
          css={inputGroupContext?.data?.css}
          {...rest}
        />
      )
    }

    const renderTooltip = () => {
      if (!tooltip) return null

      return (
        <div
          css={css({
            zIndex: 300,
            cursor: 'pointer',
          })}
        >
          <Popover
            css={css({
              marginLeft: '5px',
            })}
            {...tooltip.config}
          >
            <Popover.Trigger>
              <span>
                <InfoIcon size={14} color={vars.color.gray['60']} />
              </span>
            </Popover.Trigger>
            <Popover.Content useArrow>{tooltip.content}</Popover.Content>
          </Popover>
        </div>
      )
    }

    const renderCaption = () => {
      if (!caption) return null

      return (
        <div
          className={classNames('pq-form__input-title', {
            ['-disabled']: disabled,
          })}
          css={css({
            display: 'flex',
            ...InputTitleWrapperCss,
          })}
        >
          <div
            css={css({
              display: 'flex',
              alignItems: 'center',
            })}
          >
            <span
              className={classNames({
                ['-required']: required,
              })}
              css={InputTitleCss}
            >
              {caption}
            </span>
            {renderTooltip()}
          </div>
        </div>
      )
    }

    return (
      <div
        css={css({
          display: 'flex',
          flexDirection: 'column',
          ...inputBoardCss,
        })}
      >
        <div
          className={classNames('pq-form__input-board', {
            ['-disabled']: disabled,
          })}
          css={InputOuterWrapperCss}
        >
          {renderCaption()}
          {renderInput()}
        </div>
        <ElementError
          errors={errors}
          name={rest?.name}
          showErrorBoard={showErrorBoard}
        />
      </div>
    )
  },
)

Input.displayName = 'Input'

export { Input }
