import { PropsWithChildren, forwardRef } from 'react'
import { PopoverProps } from './types'
import classNames from 'classnames'
import { Popper } from '/@popper'
import { PopoverTrigger } from './trigger'
import { PopoverContent } from './content'

const Popover = forwardRef<HTMLDivElement, PropsWithChildren<PopoverProps>>(
  (props, forwardedRef) => {
    const { children, className, ...options } = props

    const classes = classNames('pq-popover', className)

    return (
      <Popper {...options}>
        <div
          ref={forwardedRef}
          className={classes}
          style={{ position: 'relative' }}
        >
          {children}
        </div>
      </Popper>
    )
  },
)

Popover.displayName = 'Popover'

const PopoverNamespace = Object.assign(Popover, {
  Trigger: PopoverTrigger,
  Content: PopoverContent,
})

export { PopoverNamespace as Popover }
