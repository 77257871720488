import * as react from 'react'
import { TextAreaProps } from './types'
import classNames from 'classnames'
import { TextAreaCaptionCss, TextAreaCss, TextAreaOuterCss } from './styles'
import TextareaAutosize from 'react-textarea-autosize'
import { ElementError } from '../error'
import isEmpty from 'lodash/isEmpty'

const TextArea = react.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, forwardedRef) => {
    const {
      className,
      caption,
      errors,
      showErrorBoard,
      textAreaOuterCss,
      ...rest
    } = props

    const classes = classNames('pq-form__textarea', className, {
      ['-error']: !isEmpty(errors) && !!errors[rest.name],
    })

    const renderCaption = () => {
      if (!caption) return null

      return (
        <span css={TextAreaCaptionCss} className="pq-form__textarea-caption">
          {caption}
        </span>
      )
    }

    return (
      <div css={TextAreaOuterCss(textAreaOuterCss)}>
        {renderCaption()}
        <TextareaAutosize
          data-cy="pq-form__textarea"
          className={classes}
          ref={forwardedRef}
          autoComplete="off"
          css={TextAreaCss}
          {...rest}
        />
        <ElementError
          name={rest?.name}
          errors={errors}
          showErrorBoard={showErrorBoard}
        />
      </div>
    )
  },
)

TextArea.displayName = 'Textarea'

export { TextArea }
