import classNames from 'classnames'
import { ComponentProps } from 'react'
import { captionStyle } from './caption.css.ts'

const Caption = (props: ComponentProps<'span'> & { label: string }) => {
  const { label, className, ...restProps } = props

  const classes = classNames(className, captionStyle)

  return (
    <span className={classes} {...restProps}>
      {label}
    </span>
  )
}

export { Caption }
