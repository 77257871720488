import { createContext, PropsWithChildren, useContext } from 'react'
import { EditorContextProps } from '../types'

const EditorContext = createContext<EditorContextProps | null>(null)

const EditorProvider = (props: PropsWithChildren<EditorContextProps>) => {
  const { children, ...options } = props

  return (
    <EditorContext.Provider value={options}>{children}</EditorContext.Provider>
  )
}

const useEditorContext = () => {
  const context = useContext(EditorContext)

  if (context === null) {
    throw new Error('Editor components must be wrapped in <EditorProvider />')
  }

  return context
}

export { useEditorContext, EditorProvider, EditorContext }
