import { forwardRef } from 'react'
import { PopoverContentProps } from './types'
import { PopperContent } from '/@popper'
import { popoverContentStyle } from './popover.css.ts'

const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  (props, forwardedRef) => {
    return (
      <PopperContent
        className={popoverContentStyle}
        ref={forwardedRef}
        floatingStyle={{
          zIndex: 6999,
          maxWidth: '500px',
        }}
        {...props}
      >
        {props?.children}
      </PopperContent>
    )
  },
)

PopoverContent.displayName = 'PopoverContent'

export { PopoverContent }
