import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const InputWrapper = styled.input`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: solid 2px rgba(203, 208, 223, 0.3);

  &.-error {
    border-color: rgba(255, 105, 109, 0.5);
  }

  outline: 0;
  padding-left: 1.375em;
  font-size: 0.813rem;
  line-height: 1.54;
  color: #0d1331;

  &:focus:not(.-error) {
    border: solid 2px #3f6db4;
  }
`

export const InputOuterWrapperCss = css`
  position: relative;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: #ffffff;

  &.-disabled {
    opacity: 0.5;
  }
`

export const InputTitleWrapperCss = css`
  position: absolute;
  margin-left: 1em;
  margin-top: -0.625em;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.5;
  color: #8a91a5;
  background-color: inherit;
  white-space: nowrap;
  padding: 0 0.5em;
  opacity: 1;
`

export const InputTitleCss = css`
  &.-required:after {
    content: ' *';
    color: #ff696d;
  }
`
