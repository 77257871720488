import { forwardRef } from 'react'
import { CheckboxProps } from './types'
import classNames from 'classnames'
import {
  checkboxCaptionCss,
  checkboxInputCss,
  checkboxLabelCss,
  checkboxWrapperCss,
  checkmarkCss,
} from './checkbox.css.ts'

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, forwardedRef) => {
    const {
      children,
      name,
      caption,
      disabled,
      wrapperCss,
      captionCss,
      ...rest
    } = props

    const captionClasses = classNames(
      'pq-form__checkbox-caption',
      checkboxCaptionCss,
    )

    const checkMarkClasses = classNames('pq-form__checkmark', checkmarkCss)

    return (
      <div className={checkboxWrapperCss} style={wrapperCss}>
        <label className={checkboxLabelCss} htmlFor={name}>
          <span className={captionClasses} style={captionCss}>
            {caption}
          </span>
          <input
            className={checkboxInputCss}
            data-cy="pq-form__checkbox"
            type="checkbox"
            id={name}
            autoComplete="false"
            name={name}
            ref={forwardedRef}
            disabled={disabled}
            {...rest}
          />
          <span className={checkMarkClasses} aria-disabled={disabled} />
          {children}
        </label>
      </div>
    )
  },
)

Checkbox.displayName = 'Checkbox'

export { Checkbox }
