import { Editor } from '@tiptap/react'
import { useRef } from 'react'
import { useEditorContext } from '../context'
import { Button } from '/@button'
import { PaperclipIcon } from 'lucide-react'

const EditorToolbarAttachImage = (props: { editor: Editor }) => {
  const { editor } = props

  const ref = useRef<HTMLInputElement>()

  const { onFileUpload } = useEditorContext()

  const onChange = async (e) => {
    const file = e.target.files[0]

    if (file) {
      if (onFileUpload) {
        // can create a custom command called setImagePlaceholder
        // which will set placeholder with a progress loader
        // I guess this would need to be done in addNodeView
        const response = await onFileUpload(file)
        console.log('response', response)
        const objectUrl = URL.createObjectURL(file)
        editor.chain().focus().setImage({ src: objectUrl }).run()
      }
      // pass in prop file upload handler to save to server
      // would be nice to figure out how to set a temporary loader
      // until file has finished loading
    }
  }

  return (
    <>
      <Button
        variant="transparent"
        onClick={() => {
          if (ref.current) ref.current.click()
        }}
      >
        <PaperclipIcon />
      </Button>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={ref}
        accept="image/*"
        onChange={onChange}
      />
    </>
  )
}

export { EditorToolbarAttachImage }
